.login-form{
    margin-top: 3rem;
}

.input-with-icon .key-icon,
.eye-icon{
    width: 2.5rem;
    height: 2.5rem;
}

@media only screen and (max-width:50em){
    .login-form{
        margin-top: 6rem;
    }
}
@media only screen and (max-width:37.5em){
    .login-form{
        margin-top: 10rem;
    }
}