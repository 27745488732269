@import url(https://shed-ui.netlify.app/component.css);
::-webkit-scrollbar {
  width: 0px;
}
:root {
  --discount-clr: #1c7228;
  --clr-ratings: #efa41b;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
}
.present{
  color: var(--primary-clr);
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 5px;
}
.chip {
  flex-shrink: 0;
  border-radius: 5rem;
}
.chip-container {
  padding-inline: 1rem;
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
}
.video-card-btns {
  flex-direction: column;
  right: 3rem;
  top: -2rem;
  grid-gap: 5px;
  gap: 5px;
}
.main-wrapper {
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  padding-bottom: 5rem;
  flex-direction: column;
}

.single-video-title {
  font-size: 1.75rem;
}

@media only screen and (max-width: 43.75em) {
  .single-video-title {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 26.875em) {
  .single-video-title {
    font-size: 1.25rem;
  }
  .creator-txt {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 22.5em) {
  .card-grid {
    padding: 0 1em;
  }
}

body{
    background-color: var(--theme-clr);
}
:root {
    font-size: 10px;
    box-sizing: border-box;
    --clr-white:#fff;
    --theme-clr:#1f2028;
    --overlay-clr: rgba(0, 0, 0, 0.75);
    color: white;
    --border-clr:#4b4c53;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Poppins', sans-serif;
  }
  ::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.5rem;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--clr-grey);
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #1f2028;
    background-color: var(--theme-clr);
  }
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .empty-page-image{
    max-width: 45rem;
    border-radius: 1rem;
  }
  .empty-page-title{
    margin-bottom: 2rem;
  }
  .empty-page-box{
    flex-direction: column;
  }
  .empty-page-box .btn{
    margin: 1.5rem 0;
  }
  .space-between{
    justify-content: space-between;
  }
  .border-radius {
    border-radius: 5px;
  }
  .back-arrow {
    width: 3.5rem;
    height: 2.5rem;
  }
  .plain-btn {
    color: #fff;
    color: var(--clr-white);
    border: 2px solid #4b4c53;
    border: 2px solid var(--border-clr);
    background-color: #1f2028;
    background-color: var(--theme-clr);
    height: 3.5rem;
  }
  .plain-btn:hover {
    background-color: #1f2028;
    background-color: var(--theme-clr);
  }
  .menu-icon-box > .icon.size-xs{
    width: 2rem;
    height: 2rem;
  }
 .top{
   margin-top: 8rem;
 }
.logo-container {
  grid-gap: 1rem;
  gap: 1rem;
  padding-inline: 0;
}

.profile-box {
  position: absolute;
  top: 4.5rem;
  width: 15rem;
  left: -4rem;
  max-width: 15rem;
}
.profile-box .btn {
  margin: 2px 0;
  cursor: pointer;
  background-color: var(--theme-clr);
  color: var(--clr-white);
  border: 2px solid var(--clr-white);
  font-size: 1.75rem;
}
.logo {
  justify-self: flex-start;
  cursor: pointer;
  font-family: "Permanent Marker", cursive;
  font-size: 5rem;
  color: var(--primary-clr);
  text-decoration: none;
}
.search-icon-container {
  padding-right: 1rem;
}
.search-container .search-input {
  width: 100%;
  margin-right: 2rem;
  color: var(--clr-white);
}
.search-btn {
  top: 5px;
  font-size: 2.65rem;
}
.search-container {
  max-width: 50%;
  width: 100%;
  border-color: var(--clr-white);
}
.nav-section {
  background-color: var(--theme-clr);
  justify-content: space-between;
  padding-inline: 4rem;
}
/*  Mobile Search Bar  */
.search-bar-modal {
  position: fixed;
  transition: all 0.2s linear;
  top: 0;
  z-index: 100;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--overlay-clr);
}

.search-bar {
  transition: all 0.2s linear;
  height: 10rem;
  background-color: var(--theme-clr);
  position: absolute;
  width: 100%;
  padding: 2rem 3rem;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}


.search-bar {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.search-bar-heading {
  font-weight: 600;
  margin-bottom: 1rem;
  justify-content: space-between;
}
.mobile-search-btn {
  width: 2.5rem;
  height: 2.5rem;
}
.search-bar-center {
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--clr-grey);
}

.search-bar-center input {
  font-family: inherit;
  flex-grow: 1;
  border: none;
  font-size: 1.5rem;
  color: var(--clr-white);
  background-color: var(--theme-clr);
}
.search-bar-center input:focus {
  outline: none;
}
.search-bar-center button {
  border: none;
}
.search-bar-center .btn {
  background: none;
  color: currentColor;
}
.search-wrapper {
  grid-gap: 1rem;
  gap: 1rem;
}
.mobile-search-btn {
  display: none;
}

.icon.size-xs{
  width: 2.5rem;
  height: 2.5rem;
}

@media only screen and (max-width: 37.5em) {
  .search-container {
    display: none;
  }
  .mobile-search-btn {
    display: inline-block;
  }
  .search-btn {
    width: 3.25rem;
    height: 3.25rem;
  }
  .search-container:hover {
    width: 100%;
    border: 2px solid var(--clr-black);
  }
  .avatar-container {
    margin-left: 1rem;
  }
}
@media only screen and (max-width: 31.25em) {
  .search-btn {
    top: 1px;
    right: 1rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  .avatar-container {
    width: 3rem;
    height: 3rem;
  }
  .avatar-container span {
    font-size: 1.5rem;
  }
  .search-container {
    height: 3rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .avatar-container {
    margin-left: 0;
  }
}

.sidebar-container{
    flex-direction: column;
    height: 100%;
    top:8rem;
    width: 12rem;
    z-index: 10;
    display: flex;
    overflow-y: scroll;
    position: -webkit-sticky;
    position: sticky;
    flex-shrink: 0;
    background-color: var(--theme-clr);

}
.sidebar-container .sidebar-icon-container{
    flex-direction: column;
    padding: 7.5px;
    width: 10rem;
    max-width: 100%;
    cursor: pointer;

}
.sidebar-icon-container .icon{
    margin: 5px 0;
    width:3rem;
    height: 3rem;
}
.clock-icon.icon{
    width: 2.5rem;
    height: 3rem;
}

.sidebar-icon-container:hover{
    background-color: var(--clr-grey);
}

@media only screen and (max-width:43.75em){
   .sidebar-container{
       display: none;
   }
}

.active .sidebar-icon-container{
    background-color: var(--clr-grey);
}
.sidebar-icon-container .icon.size-xs,.nav-icon-txt{
    color: var(--clr-white);
}
.card-overlay-content{
    color: var(--clr-white);
    justify-content: center;
    align-items: flex-end;
}
.content{
    flex-direction: column;
    background-color: var(--overlay-clr);
    width: 30%;
}
.card-overlay{
    box-shadow: var(--box-shadow-clr);
    margin: 1rem;
    min-width: 27rem;
    max-width: 38rem;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
}
.title{
    color: var(--clr-white);
}
.title-icon-box{
    padding-inline:2rem;
}
.video-card {
  min-width: 25rem;
  max-width: 38rem;
  flex-grow: 2;
  flex-shrink: 2;
  width: 30rem;
  margin: 1rem 0;
  flex-shrink: 1;
  border-radius: 5px;
  box-shadow: var(--box-shadow-clr);
  cursor: pointer;
}

.video-img-container img {
  max-inline-size: 100%;
  border-radius: 5px;
}
.video-details {
  padding: 0 1rem;
  justify-content: flex-start;
  margin: 1rem 0;
}
.video-desc-box {
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-height: 6rem;
}
.avatar-container{
  margin-right: 1rem;
}
.creator-name,
.video-title {
  justify-content: flex-start;
  font-weight: 600;
  font-size: 1.15rem;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

@media only screen and (max-width:40.625em) {
  .video-card {
    max-width: 100%;
  }
}
@media only screen and (max-width:21.25em) {
  .video-card {
    width: 27em;
    max-width: 27.5em;
  }
}

@media only screen and (max-width: 43.75em) {
  .video-card {
    min-width: 29rem;
  }
}

.add-playlist-box {
  background-color: var(--theme-clr);
  max-width: 30rem;
  width: -webkit-max-content;
  width: max-content;
  flex-direction: column;
  color: var(--clr-white);
  z-index: 200;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 5px;
}
.add-playlist-box .icon-close {
  width: 2.5rem;
  height: 2.5rem;
}
.checkbox {
  border-radius: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  outline: none;
  margin-right: 1rem;
}
.playlist-list {
  align-items: flex-start;
  flex-direction: column;
}
.add-playlist-box li {
  margin: 1rem 0;
  list-style-type: none;
}
.add-playlist-box .icon-box {
  justify-content: space-between;
  width: 100%;
  grid-gap: 1rem;
  gap: 1rem;
}
.overlay-box {
  position: fixed;
  opacity: 1;
  width: 100vw;
  background: var(--overlay-clr);
  height: 100%;
  left: 0;
  z-index: 200;
  margin: 0;
  top: 0;
}
.playlist-input{
    margin: 5px 0;
    border-bottom: 1px solid var(--border-clr);
    font-size: 1.75rem;
    background-color: var(--theme-clr);
    width: 100%;
    color: var(--clr-white);
    padding: 5px;
    max-width: 100%;
}
.create-playlist-btn{
    margin-right: auto;
}
.spinner-box{
 height: 1rem;   
 width: 1rem;   
}
.circle-border{
    width: 100%;
    height: 100%;
    padding: 5px;
}
.circle-inner{
    background-color: var(--theme-clr);
}
.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
.mobile-sidebar-container{
    display: none;
}
@media only screen and (max-width:43.75em){
    .sidebar-icon-container{
        padding: 1rem;
        width: 100%;
        max-width: 100%;
        cursor: pointer;
        flex-direction: column;
    }
    .sidebar-icon-container .icon{
        margin: 5px 0;
        width:3rem;
        height: 3rem;
    }
    .clock-icon.icon{
        width: 2.5rem;
        height: 3rem;
    }
    .sidebar-icon-container:hover{
        background-color: var(--clr-grey);
    }
    .mobile-sidebar-container{
        margin-left: 0;
        justify-content: space-around;
        width: 100vw;
        flex-direction: row;
        flex-shrink: 0;
        background-color: var(--theme-clr);
        top:auto;
        z-index: 10;
        bottom: -0.5px;
        position: fixed;
        display: flex;
        box-shadow: var(--box-shadow-clr);
        align-items: center;
        height: 6rem;
        max-height: 7rem; 
    }
    .sidebar-icon-container{
        margin: 0;
        padding: 0 5px;
    }
    .sidebar-icon-container .icon{
        margin: 3px 0;
        width:2.5rem;
        height: 2.5rem;
    }
    .clock-icon.icon{
        width: 2.25rem;
        height: 2.75rem;
    }
    .nav-icon-txt{
        font-size: 1.25rem;
    }
  
}

.display-img-container{
    margin-top: 0;
    overflow: hidden;
    height: 37rem;
    border-radius: 5px;
}
.display-img-container img{
    object-fit:cover;
    object-position: center;
}
.display-img-container:hover{
    -webkit-transform: scale(1);
            transform: scale(1);
}


@media only screen and (max-width:32.5em){
    .display-img-container{
        height: -webkit-max-content;
        height: max-content;
    }
}


.form-container{
    margin-top: 7rem;
    position: relative;
    height: 40%;
    width: 100%;
    margin-inline: auto;
}
.account-no,.account-yes{
    font-weight: 600;
    font-size: 1.35rem; 
}
.try-btn{
    cursor: pointer;
    font-size: 1.75rem;
    color: var(--secondary-clr);
}
input{
    background-color: var(--theme-clr);
    color: var(--clr-white);
}
.or span{
    color: var(--clr-white);
    background-color: var(--theme-clr);
}
.login-form{
    margin-top: 3rem;
}

.input-with-icon .key-icon,
.eye-icon{
    width: 2.5rem;
    height: 2.5rem;
}

@media only screen and (max-width:50em){
    .login-form{
        margin-top: 6rem;
    }
}
@media only screen and (max-width:37.5em){
    .login-form{
        margin-top: 10rem;
    }
}

.forgot-overlay{
    opacity: 1;
    height: 100vh;
    z-index: 100;
    background: var(--overlay-clr);
    transition: all .4s;
    margin-top: -7rem;
}
.close-icon{
    position: absolute;
    right: 2rem;
    width: 2.5rem;
    height: 2.5rem;
}
.forgot-password-card{
    background-color: var(--theme-clr);
}
.history-videos .video-card{
    width: 32rem;
}

.error-page {
  height: 100vh;
  background-image: url(//www.mantralabsglobal.com/wp-content/themes/spiral-child/images/error_404/white_grain.png),
    url(//www.mantralabsglobal.com/wp-content/themes/spiral-child/images/film_grain.png);
  background-color: var(--theme-clr);
}

.error-title {
  font-size: 5rem;
  font-weight: 600;
  color: var(--clr-white);
}
.error-desc {
    text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: var(--clr-white);
}
.error-letter {
  font-family: "poppins", sans-serif;
  font-size: 15rem;
  color: var(--clr-white);
  font-weight: 700;
  height: 15rem;
  display: flex;
  align-items: center;
}
.error-route-btn {
  border: 3px solid var(--clr-white);
  margin-top: 5rem;
  background-color: transparent;
}
.count-txt {
  padding: 3px 0;
  margin: 1rem 0;
  border-bottom: 3.5px solid var(--clr-white);
}
@media only screen and (max-width:47.5em) {
  .error-title {
    font-size: 3rem;
  }
  .error-letter {
      font-size: 10rem;
      height: 10rem;
  }
  .error-desc {

    font-size: 2.25rem;
  }
}

@media only screen and (max-width:37.5em) {
    .error-title {
        font-size: 2.5rem;
      }
      .error-letter {
          font-size: 10rem;
          height: 10rem;
      }
      .error-desc {
        font-size: 2rem;
      }
}

@media only screen and (max-width:28.125em) {
    .error-title {
        font-size: 2rem;
      }
      .error-letter {
          font-size: 7.5rem;
          height: 7.5rem;
      }
      .error-desc {
        font-size: 1.5rem;
      }  
}

@media only screen and (max-width:22.5em) {
    .error-title {
        font-size: 1.75rem;
      }
      .error-letter {
          font-size: 5rem;
          height: 5rem;
      }
      .error-desc {
        font-size: 1.25rem;
      }  
      .error-route-btn {
          font-size: 2rem;
      }
      .count-txt {
        font-size: 2rem;
      }
}
.short-page-container {
  align-items: flex-start;
  margin: 1rem 0;
  font-weight: bold;
  flex-direction: column;
  padding-bottom: 1rem;
  border-bottom: 2px solid;
}

.short-page-icon-box {
  grid-gap: 1rem;
  gap: 1rem;
  border: 2px solid;
  padding: 7.5px;
  border-radius: 5px;
  margin-left: 1.5rem;
}
.short-video-card {
  padding: 0 1rem;
  width: 100%;
  margin: 1rem 0;
  align-items: center;
  display: flex;
  overflow-x: scroll;
  grid-gap: 1rem;
  gap: 1rem;
  scroll-behavior: smooth;
}
.short-video-card .title {
  font-size: 1.5rem;
}

.short-video-card .short-card .video-menu-btn{
  display: none;
}
@media only screen and (max-width: 43.75em) {
  .short-video-card .short-card{
    min-width: 24rem;
  }
}

.icon-grp {
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
  padding-inline: 1rem;
}

.icon-grp .menu-icon-box {
  grid-gap: 1rem;
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.present{
  color: var(--primary-clr);
}
.menu-icon-box span{
  font-size: 2rem;
}
.video-content-wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 1rem;
  margin-bottom: 6rem;
}

.creator-txt {
  border-bottom: 2px solid;
  padding: 2.5px;
  margin: 0 auto 0 1rem;
}
.video-box {
  flex-basis: 70%;
  flex-grow: 4;
}

.feature-videos {
  margin-left: 1rem;
  flex-basis: 30%;
}

@media only screen and (max-width: 58.125em) {
  .video-content-wrapper {
    flex-direction: column;
    padding: 0;
  }

  .feature-videos {
    margin-left: 0;
    margin-inline: 1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  
  .icon-grp .menu-icon-box {
    flex-direction: column;
  }
  .menu-icon-box span{
    font-size: 1.25rem;
  }
  .icon-grp {
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

