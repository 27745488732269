@import url("https://shed-ui.netlify.app/component.css");
::-webkit-scrollbar {
  width: 0px;
}
:root {
  --discount-clr: #1c7228;
  --clr-ratings: #efa41b;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
}
.present{
  color: var(--primary-clr);
}

.card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 5px;
}
.chip {
  flex-shrink: 0;
  border-radius: 5rem;
}
.chip-container {
  padding-inline: 1rem;
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 1rem;
  gap: 1rem;
}
.video-card-btns {
  flex-direction: column;
  right: 3rem;
  top: -2rem;
  gap: 5px;
}
.main-wrapper {
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  padding-bottom: 5rem;
  flex-direction: column;
}

.single-video-title {
  font-size: 1.75rem;
}

@media only screen and (max-width: 43.75em) {
  .single-video-title {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 26.875em) {
  .single-video-title {
    font-size: 1.25rem;
  }
  .creator-txt {
    font-size: 1.35rem;
  }
}
@media only screen and (max-width: 22.5em) {
  .card-grid {
    padding: 0 1em;
  }
}
