.icon-grp {
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
  padding-inline: 1rem;
}

.icon-grp .menu-icon-box {
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
}
.present{
  color: var(--primary-clr);
}
.menu-icon-box span{
  font-size: 2rem;
}
.video-content-wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 1rem;
  margin-bottom: 6rem;
}

.creator-txt {
  border-bottom: 2px solid;
  padding: 2.5px;
  margin: 0 auto 0 1rem;
}
.video-box {
  flex-basis: 70%;
  flex-grow: 4;
}

.feature-videos {
  margin-left: 1rem;
  flex-basis: 30%;
}

@media only screen and (max-width: 58.125em) {
  .video-content-wrapper {
    flex-direction: column;
    padding: 0;
  }

  .feature-videos {
    margin-left: 0;
    margin-inline: 1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  
  .icon-grp .menu-icon-box {
    flex-direction: column;
  }
  .menu-icon-box span{
    font-size: 1.25rem;
  }
  .icon-grp {
    justify-content: center;
    gap: 2rem;
  }
}
