.spinner-box{
 height: 1rem;   
 width: 1rem;   
}
.circle-border{
    width: 100%;
    height: 100%;
    padding: 5px;
}
.circle-inner{
    background-color: var(--theme-clr);
}