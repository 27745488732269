
.forgot-overlay{
    opacity: 1;
    height: 100vh;
    z-index: 100;
    background: var(--overlay-clr);
    transition: all .4s;
    margin-top: -7rem;
}
.close-icon{
    position: absolute;
    right: 2rem;
    width: 2.5rem;
    height: 2.5rem;
}
.forgot-password-card{
    background-color: var(--theme-clr);
}